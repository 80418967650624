import '../scss/main.scss';

import gsap from "gsap";
import PureCounter from '@srexi/purecounterjs';
import SimpleLightbox from "simplelightbox";
import Swiper, {Navigation, Keyboard} from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Keyboard]);

const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

//INIT ALL FUNCTIONS
anims();
inView();
counter();
lightbox();
filterBlog();
sliders();
heightBG();
menuMobile();

function anims() {
    const transition_1 = 'fade-scale-slide-top';
    gsap.to('[data-reveal="' + transition_1 + '"]', {opacity: 1, y: 0, scale: 1, duration: 0.4, ease: 'Expo.out'});

    const body_home = document.body.classList.contains('home'),
        boxLogo = '.header-home__box-logo',
        timing = .4;

    if (body_home) {
        gsap.to(boxLogo, {
            duration: timing,
            ease: "back.out",
            stagger: timing,
            y: 0,
            autoAlpha: 1
        });
    }

    if (viewportWidth > 1024) {
        const parallaxUP = document.querySelectorAll('.parallax');
        const parallaxDOWN = document.querySelectorAll('.parallax2');

        // The parallax function
        const parallax = elements => {
            if ('undefined' !== elements && elements.length > 0) {
                elements.forEach(element => {
                    let y = window.innerHeight - element.getBoundingClientRect().top;
                    if (y > 0) {
                        element.style.transform = 'translate3d(0, -' + (0.08 * y) + 'px ,0)';
                    }
                });
            }
        }

        const parallax2 = elements => {
            if ('undefined' !== elements && elements.length > 0) {
                elements.forEach(element => {
                    let y = window.innerHeight - element.getBoundingClientRect().top;
                    if (y > 0) {
                        element.style.transform = 'translate3d(0, +' + (0.08 * y) + 'px ,0)';
                    }
                });
            }
        }

        //If element is in viewport, set its position
        parallax(parallaxUP);
        parallax2(parallaxDOWN);

        //Call the function on scroll
        window.onscroll = () => {
            parallax(parallaxUP);
            parallax2(parallaxDOWN);
        }
    }
}

function isVisible(ele) {
    const {top, bottom} = ele.getBoundingClientRect();
    const vHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (
        (top > 0 || bottom > 0) &&
        top < vHeight
    );
}

function inView() {
    let entries = document.querySelectorAll('.inview-element'),
        scroll = window.scrollY;


    window.addEventListener('scroll', function (event) {
        entries.forEach(entry => {
            if (isVisible(entry)) {
                entry.classList.add('inview');
            }
        });
    }, false);

    entries.forEach(entry => {
        if (isVisible(entry)) {
            entry.classList.add('inview');
        }
    });

}

function counter() {
    new PureCounter();
}

function lightbox() {
    const body_template_know_us = document.body.classList.contains('page-template-template-know-us');

    if (body_template_know_us) {
        let gallery = new SimpleLightbox('.know-us__gallery_images---items a');
    }
}

function filterBlog() {
    const buttons = document.querySelectorAll('.blog__posts-nav .btn');
    const posts = document.querySelectorAll('.blog__posts-items .blog__posts-item');

    function filterButtons(e) {
        e.preventDefault();
        let targetBtn = e.target.textContent.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-').toLowerCase();
        targetBtn === 'tous' ?
            posts.forEach(p => {

                p.classList.contains('hide') ?
                    p.classList.remove('hide') : null
            }) :
            posts.forEach(p => {
                p.classList.add('hide')
                p.dataset.filter === targetBtn ?
                    p.classList.remove('hide') : null
            });

        buttons.forEach(function (button) {
            button.classList.remove('active');
            e.target.classList.add('active');
        });
    }


    buttons.forEach(function (button) {
        button.addEventListener('click', filterButtons);
    });
}

function sliders() {
    const slider_customers_case = document.querySelector('.page__customers-case---cases');
    if (typeof (slider_customers_case) != 'undefined' && slider_customers_case != null) {
        new Swiper('.page__customers-case---cases', {
            centeredSlides: true,
            slidesPerView: 1,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }
}

function heightBG() {
    if (viewportWidth > 800) {
        const blocks = document.querySelectorAll('.page__block');

        blocks.forEach(function (block) {
            if (block.classList.contains('bg_black')) {
                const height_text = block.querySelector('.page__block---text').offsetHeight,
                    height_bg = height_text + 225;
                block.querySelector('.bg_black').style.height = height_bg + "px";
            }
        });
    }
}

function menuMobile() {
    if (viewportWidth < 850) {
        const menuButton = document.querySelector('#navigation--button'),
            menuMobile = document.querySelector('.navigation--mobile');

        menuButton.addEventListener('click', function () {
            menuMobile.classList.toggle('show-menu');
            menuButton.classList.toggle('close');
        });
    }
}